import { Injectable } from '@angular/core';
import { CommonService } from 'src/app/service/common/common.service';
import { BrokerMenu, CABrokerMenu, NZBrokerMenu } from './broker.menu';
import { Permissions } from './menu.permissions';
import { UnderWriterMenu } from './uw.menu';
import { environment } from 'src/environments/environment';

export interface NavigationItem {
  id: string;
  title: string;
  type: 'item' | 'collapse' | 'group';
  translate?: string;
  icon?: string;
  hidden?: boolean;
  url?: string;
  urlStartWith?: string;
  classes?: string;
  exactMatch?: boolean;
  external?: boolean;
  target?: boolean;
  breadcrumbs?: boolean;
  function?: any;
  badge?: {
    title?: string;
    type?: string;
  };
  // accessLevel?: [];
  children?: Navigation[];
}
/*
  highAccessLevels: Director, UnderwriterAgencyAgent
  midAccessLevels: OperationManager, Staff, IT, Sales, OfficeManager
  lowAccessLevels: AccountManagers, PITeam, Indo, Marketing, Finance
*/

export interface Navigation extends NavigationItem {
  children?: NavigationItem[];
}

@Injectable()
export class NavigationItem {
  constructor(private commonService: CommonService) { }
  filterMenuItems = (menuItems, accessPermissions: string[]) => {
    // Filter menu items based on access permissions
    let filteredMenuItems = menuItems.filter((menuItem) => {
      if (!menuItem.id) {
        return false;
      }
      if (!accessPermissions.includes(menuItem.id) && !menuItem.children) {
        return false;
      }
      return true;
    });

    // Filter child menu items recursively
    filteredMenuItems.forEach((menuItem) => {
      if (menuItem.children) {
        menuItem.children = this.filterMenuItems(menuItem.children, accessPermissions);
      }
    });
    filteredMenuItems = filteredMenuItems.filter(item => !item.children || item.children && item.children.length > 0);
    return filteredMenuItems;
  };

  setRegionToUrl = (region, menuItems) => {
    menuItems.forEach((menuItem) => {
      if (menuItem.url && !menuItem.url.startsWith(`/${region}/`)) {
        menuItem.url = `/${region}${menuItem.url}`;
      }
      if (menuItem.urlStartWith && !menuItem.urlStartWith.startsWith(`/${region}/`)) {
        menuItem.urlStartWith = `/${region}${menuItem.urlStartWith}`;
      }
      if (menuItem.children) {
        this.setRegionToUrl(region, menuItem.children);
      }
    });
    return menuItems;
  };

  // get broker menus by different region
  getBrokerMenuItems() {
    const region = this.commonService.getJsonValue('region');
    if (region === 'nz') {
      return JSON.parse(JSON.stringify(NZBrokerMenu.items));
    }
    if (region === 'ca') {
      return JSON.parse(JSON.stringify(CABrokerMenu.items));
    }
    return JSON.parse(JSON.stringify(BrokerMenu.items));
  }

  public get() {
    const underWriterMenuItems = JSON.parse(JSON.stringify(UnderWriterMenu.items));
    const brokerMenuItems = this.getBrokerMenuItems();
    console.log(brokerMenuItems);

    const region = this.commonService.getJsonValue('region') || environment.country.toLowerCase();
    const userAccessLevel = this.commonService.getJsonValue('accessLevel');
    const accessLevelArr = userAccessLevel.split(',');
    const permissions = Permissions.AccessLevel;
    // Find permissions for the given access level
    const accessPermissions = [];
    accessLevelArr.forEach(al => {
      if (al !== 'None' && permissions[al]) {
        accessPermissions.push(...permissions[al]);
      }
    });
    // BROKER
    if (this.commonService.getJsonValue('role') === 'BROKER') {
      console.log(accessPermissions);
      console.log(brokerMenuItems);
      let visibleMenus = this.filterMenuItems(brokerMenuItems, accessPermissions);
      visibleMenus = this.setRegionToUrl(region, visibleMenus);
      // this.commonService.setJsonValue('visibleMenus', visibleMenus);
      console.log(visibleMenus);
      return visibleMenus;
    }
    // UW
    return this.setRegionToUrl(region, underWriterMenuItems);
  }
}
