/* eslint-disable @typescript-eslint/naming-convention */
/*
  highAccessLevels: Director, UnderwriterAgencyAgent
  midAccessLevels: OperationManager, Staff, IT, Sales, OfficeManager
  lowAccessLevels: AccountManagers, PITeam, Indo, Marketing, Finance
*/

export class Permissions {
  public static AccessLevel = {
    Director: [
      'search-accountant-list',
      'paid-by-account-manager',
      'paid-by-account-manager-list',
      'paid-by-am-report-pmr',
      'paid-by-am-report-pmr-list',
      'my-workbook',
      'list-accout',
      'edit-accout',
      'assign-accout',
      'accountant-summary',
      'broker-accountant-summary-detail'
    ],
    IT: [
      'search-accountant-list',
      'auth-logs',
      'my-workbook',
      'list-accout',
      'edit-accout',
      'assign-accout',
      'accountant-summary',
      'broker-accountant-summary-detail',
      'paid-by-account-manager',
      'paid-by-account-manager-list',
      'paid-by-am-report-pmr',
      'paid-by-am-report-pmr-list',
    ],
    PITeam: [
      'search-accountant-list',
    ],
    AccountManagers: [
      'my-workbook',
      'paid-by-account-manager',
      'paid-by-account-manager-list',
      'paid-by-am-report-pmr',
      'paid-by-am-report-pmr-list',
    ],
    Finance: [
      'search-accountant-list',
      'paid-by-account-manager',
      'paid-by-account-manager-list',
      'paid-by-am-report-pmr',
      'paid-by-am-report-pmr-list',
    ],
    Marketing: [
      'search-accountant-list',
      'my-workbook'
    ],
    OperationManager: [
      'search-accountant-list',
      'my-workbook',
      'paid-by-account-manager',
      'paid-by-account-manager-list',
      'paid-by-am-report-pmr',
      'paid-by-am-report-pmr-list',
    ],
    Staff: [
      'search-accountant-list',
      'my-workbook'
    ],
    Sales: [
      'search-accountant-list',
      'my-workbook',
      'paid-by-account-manager',
      'paid-by-account-manager-list',
      'paid-by-am-report-pmr',
      'paid-by-am-report-pmr-list',
    ],
    OfficeManager: [
      'search-accountant-list',
      'my-workbook'
    ],
    UnderwriterAgencyAgent: [
      'search-accountant-list',
      'paid-by-am-report-pmr',
      'paid-by-am-report-pmr-list',
    ],
    Debtor: []
  };
}
