export class NextConfig {
  public static config = {
    layout: 'vertical', // vertical, horizontal
    subLayout: '', // horizontal-2
    collapseMenu: false,
    layoutType: 'menu-light', // menu-dark, menu-light, dark
    headerBackColor: 'background-purple', // background-blue, background-red, background-purple, background-info, background-dark
    rtlLayout: false,
    navFixedLayout: true,
    headerFixedLayout: false,
    boxLayout: false,
  };
}

export class CKEditorConfig {
  public static default = {
    removePlugins: 'about,forms,language,save,newpage,preview,print,templates',
    toolbarGroups: [
      { name: 'mode' },
      { name: 'undo' },
      { name: 'editing', groups: ['find', 'selection', 'spellchecker'] },
      { name: 'tools' },
      { name: 'basicstyles', groups: ['basicstyles', 'cleanup'] },
      { name: 'insert' },
      {
        name: 'paragraph',
        groups: ['list', 'indent', 'blocks', 'align', 'bidi'],
      },
      { name: 'links' },
      { name: 'styles' },
      { name: 'colors' },
    ],
    // eslint-disable-next-line @typescript-eslint/naming-convention
    scayt_autoStartup: false,
    allowedContent: true,
    entities: false,
    disableNativeSpellChecker: false,
    height: 450,
  };
}

export class AUState {
  public static options = [
    { label: 'ACT', value: 'ACT' },
    { label: 'NSW', value: 'NSW' },
    { label: 'NT', value: 'NT' },
    { label: 'QLD', value: 'QLD' },
    { label: 'SA', value: 'SA' },
    { label: 'TAS', value: 'TAS' },
    { label: 'VIC', value: 'VIC' },
    { label: 'WA', value: 'WA' },
    { label: 'Other', value: 'Other' },
  ];
}
export class AUAccessLevels {
  public static default = [
    { label: 'Director', value: 'Director' },
    { label: 'IT', value: 'IT' },
    { label: 'Underwriter Agency Agent', value: 'UnderwriterAgencyAgent' },
    { label: 'Sales', value: 'Sales' },
    { label: 'Account Managers', value: 'AccountManagers' },
    { label: 'Staff', value: 'Staff' },
    { label: 'Office Manager', value: 'OfficeManager' },
    { label: 'PI Team', value: 'PITeam' },
    { label: 'Indo', value: 'Indo' },
    { label: 'Marketing', value: 'Marketing' },
    { label: 'Finance', value: 'Finance' },
    { label: 'Operation Manager', value: 'OperationManager' },
    { label: 'Debtor', value: 'Debtor' },
  ];
}
export class TemplateTags {
  public static common = [
    { label: '#ADD#', value: '#ADD#' },
    { label: '#LH1#', value: '#LH1#' },
    { label: '#LH2#', value: '#LH2#' },
    { label: '#LH3#', value: '#LH3#' },
    { label: '#LH4#', value: '#LH4#' },
    { label: '#LTR#', value: '#LTR#' },
    { label: '#MSG#', value: '#MSG#' },
    { label: '#NAME#', value: '#NAME#' },
    { label: '#S2#', value: '#S2#' },
    { label: 'accountant_address', value: 'accountant_address' },
    { label: 'accountant_contact', value: 'accountant_contact' },
    { label: 'accountant_email', value: 'accountant_email' },
    { label: 'accountant_fax', value: 'accountant_fax' },
    { label: 'accountant_name', value: 'accountant_name' },
    { label: 'accountant_postal_state', value: 'accountant_postal_state' },
    {
      label: 'accountant_postal_state_fullname',
      value: 'accountant_postal_state_fullname',
    },
    { label: 'accountant_phone', value: 'accountant_phone' },
    { label: 'accountant_state', value: 'accountant_state' },
    { label: 'accountant_state_fullname', value: 'accountant_state_fullname' },
    { label: 'additional_estimate', value: 'additional_estimate' },
    { label: 'additional_estimate_date', value: 'additional_estimate_date' },
    {
      label: 'additional_estimate_reason',
      value: 'additional_estimate_reason',
    },
  ];
}
