const backendHost = 'https://dotnet-app-ca.stg-tech.tai-anchorbuild.com';
const payaiUrl = 'https://asop-ca-payai.stg-tech.tai-anchorbuild.com';
const phpSiteUrl = 'https://php-app.stg-tech.tai-anchorbuild.com';
const cognitoDomain = 'https://asop-au-staging.auth.ap-southeast-2.amazoncognito.com';
const cognitoClientId = '6uru606h0ms64595b4jo04o2f6';
const domain = 'https://asop-uw-login.stg-tech.tai-anchorbuild.com';

export const environment = {
  production: true,
  backendHost: backendHost,
  apiBasePath: backendHost + '/api',
  auApiUrl: 'https://app-win-au.stg.tai-anchorbuild.com/api',
  nzApiUrl: 'https://app-win-nz.stg.tai-anchorbuild.com/api',
  payaiUrl: payaiUrl,
  payAsUrl: 'http://pay.auditshield.ca.stg.tai.anchor.net.au',
  redirectUrl: phpSiteUrl,
  officialWebsite: 'https://www.accountancyinsurance.com.au',
  companyName: 'Accountancy Insurance',
  companyEmail: 'info@accountancyinsurance.com.au',
  companyPhone: '1300 650 758',
  country: 'CA',
  env: 'STAGING',
  cognitoUrl: phpSiteUrl + '/sso-staging-php_to_dotnet',
  cognitoLogoutUrl: `${cognitoDomain}/logout?client_id=${cognitoClientId}&logout_uri=${domain}/sso/logout`,
};
