import { DatePipe } from '@angular/common';
import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'datetimeFormatPipe'
})
export class DatetimeFormatPipe implements PipeTransform {

  transform(value: any): any {
    if (value) {
      const reg = new RegExp('-','g');//g,replace all.
      value = value.replace(reg,'/');
      const datePipe = new DatePipe('en-au');
      value = datePipe.transform(value, 'dd/MM/yyyy hh:mm:ss a');
      return value;
    }
  }

}
