export const fonts = [
  { name: 'Aberration', variance: 'Regular' },
  { name: 'Aller', variance: 'Regular, Italic, Bold' },
  { name: 'AlmagroRegular', variance: 'Regular' },
  { name: 'AmerikaSans', variance: 'Regular' },
  { name: 'AntiqueOakland', variance: 'Regular, Bold, Italic' },
  { name: 'Arial', variance: 'Regular, Bold, Italic, Bold Italic' },
  { name: 'ArialBlack', variance: 'Regular' },
  { name: 'ArialLight', variance: 'Regular, Italic, Bold' },
  { name: 'ArialNarrow', variance: 'Regular, Italic, Bold, Bold Italic' },
  { name: 'ArquitectaStdBook', variance: 'Regular' },
  { name: 'Avalon', variance: 'Regular, Italic, Bold, Bold Italic' },
  { name: 'Avenir', variance: 'Regular, Bold, Italic, Bold Italic' },
  { name: 'AvenirStdLight', variance: 'Regular, Italic' },
  { name: 'AvenirStdMedium', variance: 'Regular, Italic' },
  { name: 'AzoSans', variance: 'Regular, Italic, Bold, Bold Italic' },
  { name: 'AzoSansLight', variance: 'Regular, Italic' },
  { name: 'BakerSignetBt', variance: 'Regular' },
  { name: 'Barcode', variance: 'Regular' },
  { name: 'BookAntiqua', variance: 'Regular, Bold Italic, Italic, Bold' },
  { name: 'BookAntique', variance: 'Regular, Bold Italic, Italic, Bold' },
  { name: 'BookmanOldStyle', variance: 'Regular, Bold, Italic, Bold Italic' },
  { name: 'Broadway', variance: 'Regular' },
  { name: 'BrushScriptMT', variance: 'Regular, Italic' },
  { name: 'Calibri', variance: 'Regular, Italic, Bold, Bold Italic' },
  { name: 'CalibriLight', variance: 'Regular, Italic' },
  { name: 'Cambria', variance: 'Regular, Bold, Italic, Bold Italic' },
  { name: 'Candara', variance: 'Regular, Bold, Italic, Bold Italic' },
  { name: 'Caslon', variance: 'Regular, Bold, Italic' },
  { name: 'CaslonMedium', variance: 'Regular' },
  { name: 'CastleTLight', variance: 'Regular, Bold' },
  { name: 'CenturyGothic', variance: 'Regular, Italic, Bold, Bold Italic' },
  { name: 'CharterBT', variance: 'Regular, Bold, Italic, Bold Italic' },
  { name: 'ChrisstyExt', variance: 'Regular' },
  { name: 'Clarendon', variance: 'Regular, Bold' },
  { name: 'code128cLhr', variance: 'Regular' },
  { name: 'Code128cMHr', variance: 'Regular' },
  { name: 'ComicSans', variance: 'Regular, Bold' },
  { name: 'CopperPlateGothic', variance: 'Bold' },
  { name: 'CopperPlateGothicLight', variance: 'Regular' },
  { name: 'Corbel', variance: 'Regular, Bold, Italic, Bold Italic' },
  { name: 'Courier', variance: 'Regular, Bold Italic, Italic, Bold' },
  { name: 'DejaVuSans', variance: 'Regular, Bold, Italic, Bold Italic' },
  {
    name: 'DejaVuSansCondensed',
    variance: 'Regular, Bold, Italic, Bold Italic',
  },
  { name: 'DejaVuSansMono', variance: 'Regular, Bold, Italic, Bold Italic' },
  { name: 'DejaVuSerif', variance: 'Regular, Bold, Italic, Bold Italic' },
  {
    name: 'DejaVuSerifCondensed',
    variance: 'Regular, Bold, Italic, Bold Italic',
  },
  { name: 'DMSans', variance: 'Regular, Italic, Bold, Bold Italic' },
  { name: 'Elephant', variance: 'Regular, Italic' },
  { name: 'English157BT', variance: 'Regular' },
  { name: 'Eras', variance: 'Regular' },
  { name: 'ErasMedium', variance: 'Regular' },
  { name: 'ErasMediumITC', variance: 'Regular, Bold, Italic, Bold Italic' },
  { name: 'Eurostile', variance: 'Regular, Bold' },
  { name: 'EurostileExtended', variance: 'Regular, Bold' },
  { name: 'FranklinGothicBook', variance: 'Regular, Italic' },
  { name: 'FranklinGothicDemi', variance: 'Regular, Italic' },
  { name: 'FuturaBlack', variance: 'Regular' },
  { name: 'FuturaBold', variance: 'Regular, Italic' },
  { name: 'FuturaBoldCondensed', variance: 'Regular, Italic' },
  { name: 'FuturaBook', variance: 'Regular, Italic' },
  { name: 'FuturaExtraBlack', variance: 'Regular, Italic' },
  { name: 'FuturaExtraBlackCondensed', variance: 'Regular, Italic' },
  { name: 'FuturaHeavy', variance: 'Regular, Italic' },
  { name: 'FuturaLight', variance: 'Regular, Italic' },
  { name: 'FuturaLightCondensed', variance: 'Regular, Italic' },
  { name: 'FuturaMedium', variance: 'Regular, Italic' },
  { name: 'FuturaMediumCondensed', variance: 'Regular, Italic' },
  { name: 'Garamond', variance: 'Regular, Italic, Bold, Bold Italic' },
  { name: 'Georgia', variance: 'Regular, Italic, Bold, Bold Italic' },
  { name: 'Gillsans', variance: 'Regular, Bold, Italic, Bold Italic' },
  { name: 'GillsansLight', variance: 'Regular, Italic' },
  { name: 'GoetheGothic', variance: 'Regular, Italic, Bold' },
  { name: 'Gotham', variance: 'Regular, Italic, Bold, Bold Italic' },
  { name: 'GothamBold', variance: 'Regular, Italic' },
  { name: 'GothamLight', variance: 'Regular, Italic, Bold, Bold Italic' },
  { name: 'Helvetica', variance: 'Regular, Bold Italic, Italic, Bold' },
  { name: 'HelveticaLTLight', variance: 'Regular, Italic, Bold' },
  { name: 'HelveticaLTStd', variance: 'Regular, Italic, Bold, Bold Italic' },
  {
    name: 'HelveticaNeueLTStd',
    variance: 'Regular, Italic, Bold, Bold Italic',
  },
  { name: 'Impact', variance: 'Regular' },
  { name: 'Kristi', variance: 'Regular' },
  { name: 'KunstlerScript', variance: 'Regular' },
  { name: 'LeckerliOne', variance: 'Regular' },
  { name: 'Leelawadee', variance: 'Regular, Bold' },
  { name: 'LucidaHandwritingI', variance: 'Regular' },
  { name: 'LucidaSans', variance: 'Regular, Italic, Bold, Bold Italic' },
  { name: 'Meiryo', variance: 'Regular, Bold, Italic, Bold Italic' },
  { name: 'Mistral', variance: 'Regular' },
  { name: 'MonotypeCorsiva', variance: 'Regular' },
  { name: 'Mont', variance: 'Regular, Bold' },
  { name: 'Montserrat', variance: 'Regular, Bold, Italic, Bold Italic' },
  { name: 'Montserratlight', variance: 'Regular, Italic' },
  { name: 'MuseoSans100', variance: 'Regular' },
  { name: 'MuseoSans500', variance: 'Regular, Italic' },
  { name: 'MuseoSans700', variance: 'Regular, Italic' },
  { name: 'MuseoSans900', variance: 'Regular, Italic' },
  { name: 'MuseoSlab900', variance: 'Regular, Italic' },
  { name: 'ocrb', variance: 'Regular' },
  { name: 'OpenSans', variance: 'Regular, Italic, Bold, Bold Italic' },
  { name: 'OpenSansExtra', variance: 'Regular, Italic' },
  { name: 'OpenSansLight', variance: 'Regular, Italic' },
  { name: 'OpenSansSemi', variance: 'Regular, Italic' },
  { name: 'Parisienne', variance: 'Regular' },
  { name: 'Peignot', variance: 'Regular' },
  { name: 'ProximaNova', variance: 'Regular, Bold, Italic, Bold Italic' },
  { name: 'ProximaNovaThin', variance: 'Regular, Italic' },
  { name: 'Quicksand', variance: 'Regular, Italic, Bold, Bold Italic' },
  { name: 'RageI', variance: 'Regular' },
  { name: 'Roboto', variance: 'Regular, Bold, Italic, Bold Italic' },
  { name: 'Rubik', variance: 'Regular, Bold, Italic, Bold Italic' },
  { name: 'Rubiklight', variance: 'Regular' },
  { name: 'SegoePrint', variance: 'Regular, Bold' },
  { name: 'SegoeUILight', variance: 'Regular, Bold, Italic' },
  { name: 'SFProDisplay', variance: 'Regular, Bold, Italic, Bold Italic' },
  { name: 'SFProDisplayBlack', variance: 'Regular, Italic' },
  { name: 'SFProDisplayHeavy', variance: 'Regular, Italic' },
  { name: 'SFProDisplayLight', variance: 'Regular, Italic' },
  { name: 'SFProDisplayMedium', variance: 'Regular, Italic' },
  { name: 'SFProDisplaySemi', variance: 'Regular, Italic' },
  { name: 'SFProDisplayThin', variance: 'Regular, Italic' },
  { name: 'SFProDisplayUltralight', variance: 'Regular, Italic' },
  { name: 'Shruti', variance: 'Regular, Bold' },
  { name: 'SimSun', variance: 'Regular' },
  { name: 'SourceSansPro', variance: 'Regular, Bold, Italic, Bold Italic' },
  { name: 'Swis721', variance: 'Regular, Bold, Bold Italic' },
  { name: 'Swis721', variance: 'Regular, Bold, Bold Italic' },
  { name: 'Swis721Lt', variance: 'Regular, Italic' },
  { name: 'Swiss', variance: 'Regular, Italic, Bold, Bold Italic' },
  { name: 'Tahoma', variance: 'Regular, Italic, Bold, Bold Italic' },
  { name: 'Times', variance: 'Regular, Bold Italic, Italic, Bold' },
  { name: 'TrajanPro', variance: 'Regular, Bold' },
  { name: 'TrebuchetMS', variance: 'Regular, Bold, Italic, Bold Italic' },
  { name: 'Trirong', variance: 'Regular, Italic, Bold, Bold Italic' },
  { name: 'VectoraLH', variance: 'Regular, Italic, Bold' },
  { name: 'Verdana', variance: 'Regular, Italic, Bold, Bold Italic' },
  { name: 'YaHei', variance: 'Regular, Bold, Italic' },
  { name: 'ZurichLtCnBT', variance: 'Regular, Bold, Italic, Bold Italic' },
];
