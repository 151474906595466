<app-ui-modal #uploadTemplate dialogClass="modal-dialog-centered modal-lg">
  <div class="app-modal-header">
    <h5 class="modal-title">To upload a new template, simply fill in the relevant fields and click 'Create'</h5>
    <button type="button" class="close basic-close" data-dismiss="modal" aria-label="Close"
      (click)="uploadTemplate.hide()"><span aria-hidden="true">&times;</span></button>
  </div>
  <div class="app-modal-body">
    <form action="javascript:" [formGroup]="uploadTemplateForm">
      <div class="form-group row">
        <label class="col-sm-3 col-form-label">Template Name <span class="text-danger">*</span></label>
        <div class="col-sm-9">
          <input type="text" class="form-control" formControlName="title" placeholder="Input name ..."
            [ngClass]="{ 'is-invalid': submitted && f.title.errors }">
          <small *ngIf="submitted && f.title.errors" class="form-text text-danger">
            Policy Name is invalid.
          </small>
        </div>
      </div>
      <div class="form-group row">
        <label class="col-sm-3 col-form-label">Template File</label>
        <div class="col-sm-9">
          <file-upload formControlName="file" multiple="false" accept="image/*" style="outline-offset: 0;"
            accept="application/zip,application/x-zip-compressed">
          </file-upload>
          <small *ngIf="submitted && f.file.errors" class="form-text text-danger">
            The maximum file size allowed is 5 MB!
          </small>
        </div>
      </div>
    </form>
  </div>
  <div class="app-modal-footer">
    <button type="button" class="btn btn-outline-purple mr-4" data-dismiss="modal"
      (click)="uploadTemplate.hide()">Close</button>
    <button type="button" class="btn btn-purple" (click)="save()" [disabled]="loading">
      <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true" *ngIf="loading"></span>
      Save
    </button>
  </div>
</app-ui-modal>
