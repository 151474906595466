import { Component, EventEmitter, OnInit, Output, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { CustomValidators } from 'src/app/theme/shared/validators/custom-validators';
import { FileUploadControl, FileUploadValidators } from '@iplab/ngx-file-upload';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import { CommonService } from 'src/app/service/common/common.service';
import { DialogService } from 'src/app/service/dialog/dialog.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-upload-template',
  templateUrl: './upload-template.component.html',
  styleUrls: ['./upload-template.component.scss']
})
export class UploadTemplateComponent implements OnInit {

  @ViewChild('uploadTemplate') uploadTemplate: any;
  @Output() clickSave = new EventEmitter();

  form: any = {};
  uploadTemplateForm: FormGroup;
  submitted = false;
  loading = false;

  private filesControl = new FormControl(
    null,
    [
      FileUploadValidators.filesLimit(1),
      Validators.required,
    ]
  );

  constructor(
    private formBuilder: FormBuilder,
    private http: HttpClient,
    private router: Router,
    private commonService: CommonService,
    private dialogService: DialogService,
  ) { }

  get f() { return this.uploadTemplateForm.controls; }

  ngOnInit(): void {
    this.initForm();
  }

  initForm()
  {
    this.uploadTemplateForm = this.formBuilder.group({
      file: this.filesControl,
      title:  [null, [Validators.required]],
      type:  ['interim_payment', [Validators.required]],
    });
  }

  save() {
    this.submitted = true;
    if (this.uploadTemplateForm.invalid) {
      return;
    }
    const url = '/uw/template/store';
    const formData = this.getFormData();
    this.loading = true;
    this.http.post(url, formData)
      .subscribe((res: any) => {
        this.loading = false;
        const flag = this.dialogService.getErrorCode(res);
        if (flag) {
          Swal.fire({
            text: 'Saved successfully!',
            title: 'Success!',
            icon: 'success',
            timer: 3000,
          }).then(result => {
            this.uploadTemplate.hide();
            this.clearFrom();
            this.clickSave.emit();
          });
        }
      }, error => {
        this.loading = false;
      });
  }

  getFormData() {
    const formData = new FormData();
    const data = this.uploadTemplateForm.value;
    // get Upload Logo
    if (this.filesControl.value?.length) {
      const file = this.filesControl.value[0];
      formData.append('file', file);
    }
    formData.append('data', JSON.stringify(data));
    return formData;
  }

  clearFrom(): void {
    this.submitted = false;
    this.filesControl.setValue([]);
    this.initForm();
  }
}
