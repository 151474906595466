import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

export interface Data {
  name: string;
  data: any;
}

@Injectable({
  providedIn: 'root'
})
export class DataShareService {
  private subject = new BehaviorSubject<Data>({ name: 'default', data: null });

  constructor() { }

  sendData(data: Data) {
    this.subject.next(data);
  }

  getData(): Observable<any> {
    return this.subject.asObservable();
  }

  clearData() {
    this.subject.next({ name: 'default', data: null });
  }

}
