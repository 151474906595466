import { Pipe, PipeTransform } from '@angular/core';
import { DatePipe } from '@angular/common';

@Pipe({
  name: 'dateFormatPipe'
})
export class DateFormatPipe implements PipeTransform {

  transform(value: any): any {
    if (value) {
      if(value.indexOf(' ')>-1){
        value = value.substr(0, value.indexOf(' '));
      }
      const datePipe = new DatePipe('en-US');
      value = datePipe.transform(value, 'dd/MM/yyyy');
      return value;
    }
  }

}
