import { Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment';

@Pipe({
  name: 'convertDateFormat'
})
export class ConvertDateFormatPipe implements PipeTransform {

  transform(source: any, format: string, to = 'DD/MM/YYYY'): string {
    if (!source) {
      return '';
    }

    const date = moment(source, format);
    if (!date.isValid()) {
      return 'N/A';
    }

    return date.format(to);
  }

}
