<ngb-carousel *ngIf="imgagsBanner" [interval]="10000" [showNavigationArrows]="false" [showNavigationIndicators]="true">
  <ng-template ngbSlide *ngFor="let banner of imgagsBanner">
    <div class="picsum-img-wrapper">
      <img [src]="banner.image" alt="">
    </div>
    <div class="carousel-caption">
      <div class="d-flex tips">
        <div class="d-flex align-items-center">
          <!-- <i class="fa fa-calendar mr-2"></i> -->
          <img class="mr-2" src="assets/images/auth/icon-date.svg">
          <span>{{today}}</span>
        </div>
        <div class="text-right" style="flex: 1;">
          <a href="https://www.accountancyinsurance.com.au/index.php/contact-us" target="_blank"
            style="color: #ffffff;">Contact Us</a>
        </div>
      </div>
      <div class="caption-container">
        <div class="caption-section">
          <div class="caption-title">{{banner.title}}</div>
          <div class="caption-content mt-4">
            {{banner.subTitle}}
          </div>
          <div class="caption-description mt-4" [innerHTML]='banner.description'>
          </div>
          <div>
            <button  class="btn view-more" >FIND OUT MORE</button>
            <!-- <a class="btn view-more" [href]="banner.link" target="_blank">FIND OUT MORE</a> -->
          </div>
        </div>
      </div>
    </div>
  </ng-template>
</ngb-carousel>
