import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NgbDropdownConfig } from '@ng-bootstrap/ng-bootstrap';

import { CommonService } from '../../../../../service/common/common.service';

@Component({
  selector: 'app-nav-right',
  templateUrl: './nav-right.component.html',
  styleUrls: ['./nav-right.component.scss'],
  providers: [NgbDropdownConfig],
})
export class NavRightComponent implements OnInit {
  public username = '';
  public companyname = '';
  public firstname = '';
  public lastname = '';
  public usertype = '';
  public timer;
  public isBroker = false;

  constructor(private router: Router, public commonService: CommonService, public http: HttpClient) { }

  ngOnInit() {
    this.timer = setInterval(() => {
      this.bindUserInfo();
    }, 500);
    this.isBroker = this.commonService.getJsonValue('role') === 'BROKER';
  }

  bindUserInfo() {
    if (this.usertype) {
      clearInterval(this.timer);
    }
    console.log('-----no login-----');
    //get user detail
    this.username = this.commonService.getJsonValue('username');
    this.usertype = this.commonService.getJsonValue('usertype');
    this.companyname = this.commonService.getJsonValue('companyName');
    this.firstname = this.commonService.getJsonValue('firstName');
    this.lastname = this.commonService.getJsonValue('lastName');
  }

  logout() {
    // record logout history
    this.http.post('/user/logout', { timelogid: '' }).subscribe(res => { }, error => { });
    // checking SSO key, do not remove SSO key so user can switch other account to go SSO login
    const cognitoKey = this.commonService.getJsonValue('cognito_key');
    const expiresAt = this.commonService.getJsonValue('cognito_expires_at');

    const isSSO = this.commonService.getJsonValue('sso') === 'true';

    // remove all localstorage items
    this.commonService.clearToken();
    // set SSO key again
    if (cognitoKey) {
      this.commonService.setJsonValue('cognito_key', cognitoKey);
      this.commonService.setJsonValue('cognito_expires_at', expiresAt);
    }
    // close the window for SSO or go to login page for normal login
    if (isSSO) {
      window.close();
    } else {
      this.router.navigate(['auth/login']);
    }
  }
}
