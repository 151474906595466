import { Injectable } from '@angular/core';
import { Router, NavigationExtras } from '@angular/router';
import { CommonService } from '../common/common.service';
import { environment } from 'src/environments/environment';

@Injectable({
    providedIn: 'root'
})
export class RouteService {
    constructor(private router: Router, private commonService: CommonService) { }

    navigate(commands: string[], extras?: NavigationExtras): Promise<boolean> {
        if (commands.length === 0) {
            return null;
        }
        const region = this.commonService.getJsonValue('region') || environment.country.toLowerCase();
        const prefix = `/${region}/`;

        let routeUrl = commands[0];
        if (!routeUrl.startsWith('/')) {
            routeUrl = `/${routeUrl}`;
        }

        if (routeUrl.startsWith('/auth') || routeUrl.startsWith('/sso') || routeUrl.startsWith('/no-permission')) {
            return this.router.navigate([routeUrl], extras);
        }

        // for broker and uw router
        if (!routeUrl.startsWith(prefix)) {
            routeUrl = `/${region}${routeUrl}`;
        }

        console.log(routeUrl);

        return this.router.navigate([routeUrl], extras);
    }
}
