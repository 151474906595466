import { DOCUMENT } from '@angular/common';
import { Component, EventEmitter, Inject, Input, OnInit, Output, ViewChild } from '@angular/core';
import { HttpClient, HttpParams, HttpResponse } from '@angular/common/http';
import { TemplateTags } from 'src/app/app-config';
import Swal from 'sweetalert2';
import { fonts } from './available-fonts';
import { tplMock } from './template.mock';
import { CommonService } from 'src/app/service/common/common.service';
import { DialogService } from 'src/app/service/dialog/dialog.service';

@Component({
  selector: 'app-template-editor',
  templateUrl: './template-editor.component.html',
  styleUrls: ['./template-editor.component.scss'],
})
export class TemplateEditorComponent implements OnInit {
  @ViewChild('commonTemplateEditorModal') commonTemplateEditorModal: any;
  @ViewChild('previewTemplateModal') previewTemplateModal: any;
  @Output() closeEditor = new EventEmitter();
  @Input() type: string;

  id: number;
  prop: any = {
    tplName: '',
    tplTag: null,
    tplMultiCellTag: null,
    image: null,
    tplContent: '',
  };

  tplTagList: Array<any> = [];
  tplMultiCellTagList: Array<any> = [
    { label: 'Multicell Start', value: 'multicell_start' },
    { label: 'Multicell End', value: 'multicell_end' },
  ];

  imageList: Array<any> = [];

  availableFontList: Array<any> = fonts;

  codemirrorOptions: any = {
    mode: 'text/html',
    autoCloseTags: true,
    styleActiveLine: true,
    lineNumbers: true,
    lineWrapping: true,
    viewportMargin: Infinity,
    smartIndent: false,
  };

  loading = false;
  triggerEmitter = false;
  selectedFile: any = null;
  selectedFileName = '';
  requiredFileType = '.jpg, .jpeg, .png';
  emailContent = '';
  warning = '';

  public tplEditor = {
    instance: null,
    onInsertClick: () => {},
    onAlignmentInsertClick: () => {},
    onImageInsert: () => {},
    undoLastAction: () => {},
  };
  private window: any;

  constructor(
    @Inject(DOCUMENT) private document: Document,
    private http: HttpClient,
    private commonService: CommonService,
    private dialogService: DialogService,
  ) {
    this.window = this.document.defaultView;
  }

  ngOnInit(): void { }

  initEditor() {
    const editorElement = document.getElementById('codemirrorArea');
    if (!this.tplEditor.instance) {
      const editorInstance = this.window.CodeMirror.fromTextArea(
        editorElement,
        this.codemirrorOptions
      );
      const insertAtCursor = (field, value) => {
        editorInstance.replaceSelection(value);
        // console.log(value);
        if ((document as any).selection) {
          // IE
          field.focus();
          const sel = (document as any).selection.createRange();
          sel.text = value;
          field.focus();
        } else if (field.selectionStart || field.selectionStart === '0') {
          // FF
          const startPos = field.selectionStart;
          const endPos = field.selectionEnd;
          const cursorPos = field.selectionEnd;
          const scrollTop = field.scrollTop;
          field.value =
            field.value.substring(0, startPos) +
            value +
            field.value.substring(endPos, field.value.length);
          field.focus();
          field.selectionStart = cursorPos;
          field.selectionEnd = cursorPos;
          field.scrollTop = scrollTop;
        } else {
          field.value += value;
          field.focus();
        }
      };

      const onInsertClick = () => {
        if (this.prop.tplTag) {
          const placeholdersWithAttrArray = [
            'macquarie_invoice_slip',
            'macquarie_invoice_slip_new',
          ];
          const placeholdersWithHashtagsArray = [
            '#ADD#',
            '#LH1#',
            '#LH2#',
            '#LH3#',
            '#LH4#',
            '#LTR#',
            '#MSG#',
            '#NAME#',
            '#S2#',
          ];

          if (placeholdersWithAttrArray.includes(this.prop.tplTag)) {
            insertAtCursor(
              editorElement,
              `{${this.prop.tplTag} top=601pt left=25pt}`
            );
          } else if (
            placeholdersWithHashtagsArray.includes(this.prop.tplTag)
          ) {
            insertAtCursor(editorElement, this.prop.tplTag);
          } else if (this.prop.tplTag === 'barcode_type_1') {
            insertAtCursor(editorElement, '{barcode type=1}');
          } else if (this.prop.tplTag === 'barcode_type_2') {
            insertAtCursor(editorElement, '{barcode type=2}');
          } else if (this.prop.tplTag === 'faq_template_plugin') {
            insertAtCursor(editorElement, `{${this.prop.tplTag}}`);
          } else {
            insertAtCursor(editorElement, `{$${this.prop.tplTag}}`);
          }
        }
      };

      const onAlignmentInsertClick = () => {
        if (this.prop.tplMultiCellTag) {
          insertAtCursor(editorElement, `{${this.prop.tplMultiCellTag}}`);
        }
      };

      const onImageInsert = () => {
        if (this.prop.image) {
          insertAtCursor(editorElement, `<img src="${this.prop.image}">`);
        }
      };

      const undoLastAction = () => {
        editorInstance.doc.undoSelection();
      };

      this.tplEditor.instance = editorInstance;
      this.tplEditor.onInsertClick = onInsertClick;
      this.tplEditor.onAlignmentInsertClick = onAlignmentInsertClick;
      this.tplEditor.onImageInsert = onImageInsert;
      this.tplEditor.undoLastAction = undoLastAction;
    }
  }

  show(templateId, templateName) {
    this.resetData();
    this.id = templateId;
    this.prop.tplName = templateName;

    this.commonTemplateEditorModal.show();

    this.initEditor();
    this.loadTemplateFonts();
    this.loadTemplateTags();
    this.loadTemplateDetails(templateId);
  }

  close() {
    this.resetData();
    this.commonTemplateEditorModal.hide();
    if (this.triggerEmitter) {
      this.closeEditor.emit();
    }
  }

  loadTemplateTags() {
    if (this.type) {
      this.loading = true;

      this.http.get('/uw/template/get-tags?type='+this.type).subscribe((data: any) => {
        this.loading = false;
        const flag = this.dialogService.getErrorCode(data);
        if (flag) {
          const res = [];
          for (const list of data) {
            const groupName = list.groupName;

            for (const item of list.tags) {
              res.push({
                groupName: groupName,
                label: item.key,
                value: item.key,
              });
            }
          }

          this.tplTagList = res;
        }
      }, error => {
        this.loading = false;
        console.log('HTTP Error', error);
      });
    }
  }

  loadTemplateFonts() {
    this.loading = true;

    this.http.get('/uw/template/get-available-fonts').subscribe((data: any) => {
      this.loading = false;
      const flag = this.dialogService.getErrorCode(data);
      if (flag) {
        this.availableFontList = data;
      }
    }, error => {
      this.loading = false;
      console.log('HTTP Error', error);
    });
  }

  loadTemplateDetails(templateId) {
    if (templateId) {
      this.loading = true;
      this.http.get(`/uw/template/detail?id=${templateId}`).subscribe((data: any) => {
        this.loading = false;
        const flag = this.dialogService.getErrorCode(data);
        if (flag && this.tplEditor.instance) {
          this.tplEditor.instance.setValue(data.tplContent);
          this.imageList = data.tplImages;
          this.warning = data.warning;
          if (data.template) {
            this.prop.tplName = data.template.title;
          }
        }
      }, error => {
        this.loading = false;
        console.log('HTTP Error', error);
      });
    }
  }

  imageSelected(file) {
    this.clearSelectedImg();
    if (file.length) {
      this.selectedFile = file[0];
      this.selectedFileName = file[0].name;
    }
  }

  clearSelectedImg() {
    this.selectedFileName = '';
    this.selectedFile = null;
  }

  uploadImage() {
    if (!this.selectedFile) {
      return false;
    }

    const data = {
      id: this.id
    };

    const formData = new FormData();
    formData.append('file', this.selectedFile);
    formData.append('data', JSON.stringify(data));

    this.loading = true;
    const url = '/uw/template/upload-image';
    this.http.post(url, formData)
      .subscribe((res: any) => {
        this.loading = false;
        const flag = this.dialogService.getErrorCode(res);
        if (flag) {
          this.clearSelectedImg();
          Swal.fire({
            html: 'Uploaded successfully!',
            title: 'Success!',
            icon: 'success',
          }).then(result => {
            this.imageList = res.tplImages;
          });
        }
      }, error => {
        this.loading = false;
      });
  }

  deleteImage() {
    if (this.prop.image) {
      Swal.fire({
        text: `Are you sure you wish to delete image "${this.prop.image}"?`,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Yes',
        cancelButtonText: 'No',
      }).then((result) => {
        if (!result.value) {
          return false;
        }
        this.loading = true;
        this.http.get(`/uw/template/delete-image?id=${this.id}&img=${this.prop.image}`).subscribe((data: any) => {
          this.loading = false;
          const flag = this.dialogService.getErrorCode(data);
          if (flag) {
            this.imageList = data.tplImages;
            this.prop.image = '';

            Swal.fire({
              text: 'The image has been deleted.',
              title: 'Success!',
              icon: 'success',
              timer: 3000,
            });
          }
        }, error => {
          this.loading = false;
          console.log('HTTP Error', error);
        });
      });
    }
  }

  save(closeWindow) {
    const content = this.tplEditor.instance.getValue();

    const data = {
      id: this.id,
      templateContent: content
    };

    const formData = new FormData();
    formData.append('data', JSON.stringify(data));

    this.loading = true;
    this.http.post(`/uw/template/save-content`, data).subscribe((res: any) => {
      this.loading = false;

      const flag = this.dialogService.getErrorCode(res);
      if (flag) {
        if (res.success) {
          Swal.fire({
            text: 'The template has been saved.',
            title: 'Success!',
            icon: 'success',
            timer: 3000,
          }).then(r => {
            if (closeWindow) {
              this.close();
            }
          });
        } else {
          Swal.fire({
            text: res.message,
            html: res.message,
            icon: 'warning',
          });
        }

      }
    }, error => {
      this.loading = false;
      console.log('HTTP Error', error);
    });

    return;
  }

  renameTemplate() {
    if (!this.prop.tplName) {
      Swal.fire({
        text: 'Please enter the template name.',
        title: 'Warning!',
        icon: 'warning',
        timer: 3000,
      });
      return;
    }

    const data = {
      id: this.id,
      title: this.prop.tplName
    };

    this.loading = true;
    this.http.post(`/uw/template/rename-template`, data).subscribe((res: any) => {
      this.loading = false;

      const flag = this.dialogService.getErrorCode(res);
      if (flag) {
        this.triggerEmitter = true;
        Swal.fire({
          text: 'The template name has been saved.',
          title: 'Success!',
          icon: 'success',
          timer: 3000,
        });
      }
    }, error => {
      this.loading = false;
      console.log('HTTP Error', error);
    });
  }

  resetData() {
    this.id = 0;
    this.prop = {
      tplName: '',
      tplTag: null,
      tplMultiCellTag: null,
      image: null,
      tplContent: '',
    };

    this.imageList = [];
    this.tplTagList = [];
    this.selectedFile = null;
    this.selectedFileName = '';
    this.warning = '';

    if (this.tplEditor.instance) {
      this.tplEditor.instance.setValue('');
    }
  }

  previewTemplate()
  {
    const content = this.tplEditor.instance.getValue();

    const data = {
      id: this.id,
      templateContent: content
    };

    const formData = new FormData();
    formData.append('data', JSON.stringify(data));

    this.loading = true;
    this.http.post(`/uw/template/preview-content`, data).subscribe((res: any) => {
      this.loading = false;
      const flag = this.dialogService.getErrorCode(res);
      if (flag) {
        if (res.type === 'pdf') {
          this.commonService.openNewWindow(res.result);
        } else {
          this.emailContent = res.result;
          this.previewTemplateModal.show();
        }
      }
    }, error => {
      this.loading = false;
      console.log('HTTP Error', error);
    });
  }
}
