export class UnderWriterMenu {
  public static items = [
    {
      id: 'navigation',
      title: 'Navigation',
      type: 'group',
      icon: 'feather icon-align-left',
      children: [
        {
          id: 'home',
          title: 'Home',
          type: 'item',
          url: '/uw/home',
          urlStartWith: '/uw/home',
          classes: 'nav-item',
          icon: 'feather icon-home',
        },
        {
          id: 'admin',
          title: 'Admin',
          type: 'collapse',
          icon: 'feather icon-grid',
          children: [
            {
              id: 'add-account',
              title: 'Add Account',
              type: 'item',
              url: '/uw/admin/add-account',
              hidden: true,
            },
            {
              id: 'account-list',
              title: 'Account List',
              type: 'item',
              url: '/uw/admin/account-list',
              hidden: true,
            },
            {
              id: 'edit-account',
              title: 'Edit Account',
              type: 'item',
              url: '/uw/admin/edit-account',
              hidden: true,
              urlStartWith: `/uw/admin/edit-account`,
            },
            {
              id: 'change-password',
              title: 'Change Password',
              type: 'item',
              url: '/uw/admin/change-password',
              hidden: true,
            },
            {
              id: 'stamp-duty-gst',
              title: 'Stamp Duty & GST',
              type: 'item',
              url: '/uw/admin/stamp-duty-gst',
            },
            {
              id: 'underwriter-list',
              title: 'Underwriter',
              type: 'item',
              url: '/uw/admin/uw-list',
            },
            {
              id: 'policy-schedule-wordings-tpl',
              title: 'Policy Schedule & Wordings Templates',
              type: 'item',
              url: '/uw/admin/policy-schedule-wordings-tpl',
            },
            {
              id: 'list-policies',
              title: 'List Policies',
              type: 'item',
              url: '/uw/admin/policy-list',
            },
            {
              id: 'create-new-policy',
              title: 'Create New Policy',
              type: 'item',
              url: '/uw/admin/create-policy',
            },
            {
              id: 'interim-payment-tpl',
              title: 'Interim Payment Templates',
              type: 'item',
              url: '/uw/admin/interim-payment-tpl',
            },
          ],
        },
        {
          id: 'claims',
          title: 'Claims',
          type: 'collapse',
          icon: 'feather icon-zap',
          children: [
            {
              id: 'search-claims',
              title: 'Search Claims',
              type: 'item',
              url: '/uw/claims/search-claims',
            },
            {
              id: 'list-claims',
              title: 'List Claims',
              type: 'item',
              url: '/uw/claims/list-claims',
              hidden: true,
              urlStartWith: `/uw/claims/list-claim`,
            },
            {
              id: 'edit-claim',
              title: 'Edit Claim',
              type: 'item',
              url: '/uw/claims/edit-claim',
              hidden: true,
              urlStartWith: `/uw/claims/edit-claim`,
            },
            {
              id: 'view-claim',
              title: 'View Claim',
              type: 'item',
              url: '/uw/claims/view-claim',
              hidden: true,
              urlStartWith: `/uw/claims/view-claim`,
            },
            {
              id: 'review-claim',
              title: 'Review Claim',
              type: 'item',
              url: '/uw/claims/review-claim',
              hidden: true,
              urlStartWith: `/uw/claims/review-claim`,
            },
            {
              id: 'register-claim',
              title: 'Register a Claim',
              type: 'item',
              url: '/uw/claims/register-claim',
            },
            {
              id: 'settlement-report',
              title: 'Settlement Report',
              type: 'item',
              hidden: true,
              url: '/uw/claims/settlement-report',
            },
            {
              id: 'email-templates',
              title: 'Email Templates',
              type: 'item',
              url: '/uw/claims/email-templates',
            },
            {
              id: 'underwriting-notes',
              title: 'Underwriting Notes',
              type: 'item',
              url: '/uw/claims/underwriting-notes',
            },
          ],
        },
        {
          id: 'rates',
          title: 'Rates',
          type: 'collapse',
          icon: 'feather icon-activity',
          hidden: false,
          children: [
            {
              id: 'list-rate-set',
              title: 'List Rate Set',
              type: 'item',
              url: '/uw/rates/list-rate-set',
            },
            {
              id: 'edit-rate-set',
              title: 'Edit Rate Set',
              type: 'item',
              url: '/uw/rates/edit-rate-set',
              hidden: true,
              urlStartWith: `/uw/rates/edit-rate-set?`,
            },
            {
              id: 'view-rate-set',
              title: 'View Rate Set',
              type: 'item',
              url: '/uw/rates/view-rate-set',
              hidden: true,
              urlStartWith: `/uw/rates/view-rate-set?`,
            },
            {
              id: 'Create-Rate-Set',
              title: 'Create Rate Set',
              type: 'item',
              url: '/uw/rates/create-rate-set',
            },
            {
              id: 'Create-Category',
              title: 'Create Category',
              type: 'item',
              url: '/uw/rates/create-category',
            },
            {
              id: 'Edit-Category',
              title: 'Edit Category',
              type: 'item',
              hidden: true,
              url: '/uw/rates/edit-category',
              urlStartWith: `/uw/rates/edit-category`,
            },
            {
              id: 'View-Category',
              title: 'View Category',
              type: 'item',
              hidden: true,
              url: '/uw/rates/view-category',
              urlStartWith: `/uw/rates/view-category`,
            },
            {
              id: 'list-category',
              title: 'List Category',
              type: 'item',
              url: '/uw/rates/list-category',
            },
            {
              id: 'list-rate-set-group',
              title: 'List Rate Set Group',
              type: 'item',
              url: '/uw/rates/list-rate-set-group',
            },
            {
              id: 'create-rate-set-group',
              title: 'Create Rate Set Group',
              type: 'item',
              url: '/uw/rates/create-rate-set-group',
            },
            {
              id: 'edit-rate-set-group',
              title: 'Edit Rate Set Group',
              type: 'item',
              hidden: true,
              url: '/uw/rates/edit-rate-set-group',
              urlStartWith: `/uw/rates/edit-rate-set-group`,
            },
          ],
        },
        {
          id: 'broker',
          title: 'Broker',
          type: 'collapse',
          icon: 'feather icon-bold',
          hidden: true,
          children: [
            {
              id: 'list-broker',
              title: 'List Broker',
              type: 'item',
              url: '/uw/broker/list-broker',
            },
            {
              id: 'edit-broker',
              title: 'Edit Broker',
              type: 'item',
              hidden: true,
              urlStartWith: `/uw/broker/edit-broker`,
              url: '/uw/broker/edit-broker',
            },
            {
              id: 'create-broker',
              title: 'Create Broker',
              type: 'item',
              url: '/uw/broker/create-broker',
            },
            {
              id: 'search-broker',
              title: 'Search Broker',
              type: 'item',
              url: '/uw/broker/search-broker',
            },
            {
              id: 're-assign-accountant',
              title: 'Re-Assign Accountant',
              type: 'item',
              url: '/uw/broker/re-assign-accountant',
            },
            {
              id: 'broker-summary',
              title: 'Broker Summary',
              type: 'item',
              hidden: true,
              url: '/uw/broker/broker-summary',
            },
          ],
        },
        {
          id: 'reports',
          title: 'Reports',
          type: 'collapse',
          icon: 'feather icon-file-text',
          hidden: false,
          children: [
            {
              id: 'all-paid-client',
              title: 'All Paid Client',
              type: 'item',
              hidden: true,
              url: '/uw/reports/all-paid-client',
            },
            {
              id: 'closing-broker',
              title: 'Closing Broker',
              type: 'item',
              hidden: true,
              url: '/uw/reports/closing-broker',
            },
            {
              id: 'claim-detailed',
              title: 'Claim Detailed',
              type: 'item',
              hidden: true,
              url: '/uw/reports/claim-detailed',
            },
            {
              id: 'claim-register',
              title: 'Claim Register',
              type: 'item',
              hidden: false,
              url: '/uw/reports/claim-register',
            },
            {
              id: 'claim-register-report',
              title: 'Claim Register Report',
              type: 'item',
              hidden: true,
              url: '/uw/reports/claim-register-report',
              urlStartWith: `/uw/reports/claim-register-report`,
            },
            {
              id: 'all-accountant',
              title: 'All Accountant',
              type: 'item',
              hidden: true,
              url: '/uw/reports/all-accountant',
            },
            {
              id: 'claim-activity',
              title: 'Claim Activity',
              type: 'item',
              hidden: true,
              url: '/uw/reports/claim-activity',
            },
            {
              id: 'accountant-summary',
              title: 'Accountant Summary',
              type: 'item',
              hidden: false,
              url: '/uw/reports/accountant-summary',
              urlStartWith: `/uw/reports/accountant-summary`
            },
            {
              id: 'accountant-summary-detail',
              title: 'Accountant Summary',
              type: 'item',
              hidden: true,
              url: '/uw/reports/accountant-summary-detail',
              urlStartWith: `/uw/reports/accountant-summary-detail`,
            },
            {
              id: 'search-accountant-loss-ratio',
              title: 'All Accountant Loss Ratio',
              type: 'item',
              hidden: true,
              url: '/uw/reports/search-accountant-loss-ratio',
            },
            {
              id: 'all-accountant-loss-ratio',
              title: 'All Accountant Loss Ratio',
              type: 'item',
              url: '/uw/reports/all-accountant-loss-ratio',
              hidden: true,
              urlStartWith: `/uw/reports/all-accountant-loss-ratio`,
            },
            {
              id: 'claim-paid-report',
              title: 'Claim Paid Report',
              type: 'item',
              url: '/uw/reports/claim-paid-report',
            },
            {
              id: 'custom-field-report',
              title: 'Custom Field Report',
              type: 'item',
              hidden: true,
              url: '/uw/reports/custom-field-report',
            },
            {
              id: 'claim-by-category',
              title: 'Claim by Category',
              type: 'item',
              hidden: true,
              url: '/uw/reports/claim-by-category',
            },
            {
              id: 'loss-ratio-by-claim-type',
              title: 'Loss Ratio by Claim Type',
              type: 'item',
              hidden: true,
              url: '/uw/reports/loss-ratio-by-claim-type',
            },
            {
              id: 'claim-matrix-report',
              title: 'Claim Matrix Report',
              type: 'item',
              hidden: true,
              url: '/uw/reports/claim-matrix-report',
            },
            {
              id: 'open-claim-report',
              title: 'Open Claim Report',
              type: 'item',
              hidden: true,
              url: '/uw/reports/open-claim-report',
            },
            {
              id: 'claims-report-by-claim-type',
              title: 'Claim Report by Claim Type',
              type: 'item',
              hidden: true,
              url: '/uw/reports/claims-report-by-claim-type',
            },
          ],
        },
      ],
    },
  ];
}
