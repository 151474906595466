import { Directive, forwardRef, Input } from '@angular/core';
import { Validator, AbstractControl, NG_VALIDATORS } from '@angular/forms';

@Directive({
  // eslint-disable-next-line @angular-eslint/directive-selector
  selector: `[validateEmail]`,
  providers: [
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => EmailValidatorDirective),
      multi: true
    }
  ]
})
export class EmailValidatorDirective implements Validator {
  constructor() {
  }
  validate(c: AbstractControl): { [key: string]: any } {
    const v = c.value;
    if(v){
      const result = this.validateEmail(v);
      // console.log(result);
      if(!result){
          return { validateEmail: 'Email format is incorrect.' };
      }
      return null;
    }
  }

  validateEmail(email: string): boolean {
    //console.log(email);
    // Email Regex from: https://github.com/angular/angular/blob/master/packages/forms/src/validators.ts
    const EMAIL_REGEXP =
    /[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}$/;
     // /^(?=.{1,254}$)(?=.{1,64}@)[-!#$%&'*+/0-9=?A-Z^_`a-z{|}~]+(\.[-!#$%&'*+/0-9=?A-Z^_`a-z{|}~]+)*@[A-Za-z0-9]([A-Za-z0-9-]{0,61}[A-Za-z0-9])?(\.[A-Za-z0-9]([A-Za-z0-9-]{0,61}[A-Za-z0-9])?)*$/;
    return EMAIL_REGEXP.test(String(email).toLowerCase());
  }
}
