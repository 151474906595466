import { Injectable } from '@angular/core';
import Swal from 'sweetalert2';
import { Observable } from 'rxjs';
import 'rxjs/add/observable/of';

@Injectable({
  providedIn: 'root',
})
export class DialogService {
  getErrorCode(data: any) {
    let flag = true;
    const status = parseInt(data.status, 10);
    if (status === 400 || status === 415) {
      flag = false;
      if (data.title === 'One or more validation errors occurred.') {
        Swal.fire({
          text: 'Please review the information that was entered. One or more fields have a data validation error.',
          icon: 'warning',
        });
      } else {
        Swal.fire({
          text: data.title,
          icon: 'warning',
        });
      }
      return flag;
    }
    const code = data.code;
    if (code === 101 || code === 102 || code === 100) {
      flag = false;
      Swal.fire({
        text: data.message,
        html: data.message,
        icon: 'warning',
      });
      return flag;
    }
    return flag;
  }

  //check file
  checkFileError(response) {
    console.log(response.type);
    if (response.type.indexOf('application/json') !== -1) {
      const reader = new FileReader();
      reader.addEventListener('loadend', (e) => {
        // eslint-disable-next-line @typescript-eslint/dot-notation
        const res = JSON.parse(e.srcElement['result']);
        Swal.fire({
          text: res.message,
          icon: 'warning',
          showConfirmButton: false,
          timer: 3000,
        });
      });
      reader.readAsText(response);
      return false;
    } else {
      return true;
    }
  }
  confirm(message?: string): Observable<boolean> {
    const confirmation = window.confirm(message || 'Are you sure?');

    return Observable.of(confirmation);
  }
}
