import { Component, OnInit } from '@angular/core';
import { CommonService } from 'src/app/service/common/common.service';
import { RouteService } from 'src/app/service/route/route.service';

@Component({
  selector: 'app-nav-search',
  templateUrl: './nav-search.component.html',
  styleUrls: ['./nav-search.component.scss']
})
export class NavSearchComponent implements OnInit {
  public isSearch: boolean;
  public searchText = '';

  constructor(private routeService: RouteService, private commonService: CommonService) {
    this.isSearch = false;
  }

  ngOnInit() { }

  searchAccountant() {
    this.routeService.navigate([`/broker/accountant/search-accountant-list`], {
      queryParams: {
        _params: this.commonService.paramsEncode({ searchText: this.searchText, timestamp: new Date().getTime() })
      }
    });
    // this.searchText = '';
  }

}
