<app-ui-modal #commonTemplateEditorModal dialogClass="modal-dialog-centered template-editor" [containerClick]="false">
    <div class="app-modal-header">
        <h5 class="modal-title">
            <i class="feather icon-edit"></i>
            Edit template
        </h5>

        <button type="button" class="close basic-close" data-dismiss="modal" aria-label="Close" (click)="close()">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>

    <div class="app-modal-body">
        <div class="row" *ngIf="warning">
          <div class="col-12 mb-4">
            <div [innerHTML]="warning | html"></div>
            <hr>
          </div>
        </div>
        <div class="row">
            <div class="col-sm-5">

                <div class="form-group row">
                    <label class="col-sm-3 col-form-label">Template name:</label>
                    <input type="text" class="form-control col-sm-5" [(ngModel)]="prop.tplName" id="templateName" placeholder="Template name ...">
                    <div class="col-sm-4">
                        <button class="btn btn-outline-purple auto-width" (click)="renameTemplate()" popoverClass="custom-popover small-popover" container="body" ngbPopover="Rename Template" triggers="mouseenter:mouseleave"> <i class="feather icon-check-circle"></i></button>
                    </div>
                </div>

                <div class="form-group row">
                    <label class="col-sm-3 col-form-label">Template placeholders:</label>
                    <div class="col-sm-5 pl-0 pr-0">
                        <ng-select [items]="tplTagList" [clearable]="true" [(ngModel)]="prop.tplTag" groupBy="groupName" bindLabel="label" bindValue="value" placeholder="Select ...">
                            <ng-template ng-label-tmp let-item="item">
                                {{item.label}}
                            </ng-template>
                            <ng-template ng-optgroup-tmp let-item="item" let-index="index">
                                <div *ngIf="item.groupName">
                                  {{item.groupName}}
                                </div>
                            </ng-template>
                            <ng-template ng-option-tmp let-item="item" let-index="index" let-search="searchTerm">
                                {{item.value}}
                            </ng-template>
                        </ng-select>
                    </div>
                    <div class="col-sm-4">
                        <button class="btn btn-outline-purple auto-width" (click)="tplEditor.onInsertClick()" popoverClass="custom-popover small-popover" container="body" ngbPopover="Insert Placeholder" triggers="mouseenter:mouseleave">
                            <i class="feather icon-plus"></i>
                        </button>
                    </div>
                </div>

                <div class="form-group row">
                    <label class="col-sm-3 col-form-label"></label>
                    <div class="col-sm-5 pl-0 pr-0">
                        <ng-select [items]="tplMultiCellTagList" [clearable]="true" [(ngModel)]="prop.tplMultiCellTag" bindLabel="label" bindValue="value" placeholder="Select ...">
                        </ng-select>
                    </div>
                    <div class="col-sm-4">
                        <button class="btn btn-outline-purple auto-width" (click)="tplEditor.onAlignmentInsertClick()" popoverClass="custom-popover small-popover" container="body" ngbPopover="Insert Multicell Tag" triggers="mouseenter:mouseleave"> <i class="feather icon-plus"></i></button>
                    </div>
                </div>

            </div>

            <div class="col-sm-5">
                <div class="form-group row">
                    <label class="col-sm-3 col-form-label">Image Upload:<br>&nbsp;</label>
                    <div class="col-sm-5">
                        <div class="custom-file">
                            <input type="file" class="custom-file-input" id="validatedCustomFile" (change)="imageSelected($event.target.files)" [accept]="requiredFileType" required>
                            <label class="custom-file-label" for="validatedCustomFile">{{selectedFileName || 'Choose file...'}}</label>
                        </div>
                        <span class="text-danger">The maximum file size allowed is 5 MB!</span>
                    </div>
                    <div class="col-sm-4">
                        <button class="btn btn-outline-purple auto-width" (click)="uploadImage()" popoverClass="custom-popover small-popover" container="body" ngbPopover="Upload Image" triggers="mouseenter:mouseleave"> <i class="feather icon-upload"></i></button>
                    </div>
                </div>

                <div class="form-group row">
                    <label class="col-sm-3 col-form-label">Image Management:</label>
                    <div class="col-sm-5">
                        <div class="custom-file">
                            <ng-select [items]="imageList" [clearable]="true" [(ngModel)]="prop.image" bindLabel="label" bindValue="value" placeholder="Select ...">
                            </ng-select>
                        </div>
                    </div>
                    <div class="col-sm-4">
                        <button class="btn btn-outline-purple auto-width mr-2" (click)="tplEditor.onImageInsert()" popoverClass="custom-popover small-popover" container="body" ngbPopover="Insert Image" triggers="mouseenter:mouseleave"> <i class="feather icon-plus"></i></button>
                        <button class="btn btn-outline-purple auto-width mr-2" (click)="deleteImage()" popoverClass="custom-popover small-popover" container="body" ngbPopover="Delete Image" triggers="mouseenter:mouseleave"> <i class="feather icon-minus"></i></button>
                        <button class="btn btn-outline-purple auto-width" (click)="tplEditor.undoLastAction()" popoverClass="custom-popover small-popover" container="body" ngbPopover="Undo" triggers="mouseenter:mouseleave"> <i class="feather icon-rotate-ccw"></i></button>
                    </div>
                </div>
            </div>

            <div class="col-sm-2">
                <div class="form-group">
                    <div class="dropdown shortcuts-dropdown" ngbDropdown placement="auto" container="body">
                        <a href="javascript:" ngbDropdownToggle data-toggle="dropdown" class="text-purple">
                            <i class="icon feather icon-command"></i>
                            <span class="ml-2">Editor Shortcuts</span>
                        </a>
                        <div class="dropdown-menu dropdown-menu-left shortcuts-dropdown-menu" ngbDropdownMenu>
                            <table class="table custom-mini-table">
                                <tbody>
                                    <tr>
                                        <th><b>Command</b></th>
                                        <th><b>Short Cut</b></th>
                                    </tr>
                                    <tr>
                                        <td>Start searching</td>
                                        <td>Ctrl-F / Cmd-F</td>
                                    </tr>
                                    <tr>
                                        <td>Find next</td>
                                        <td>Ctrl-G / Cmd-G</td>
                                    </tr>
                                    <tr>
                                        <td>Find previous</td>
                                        <td>Shift-Ctrl-G / Shift-Cmd-G</td>
                                    </tr>
                                    <tr>
                                        <td>Replace</td>
                                        <td>Shift-Ctrl-F / Cmd-Alt-F</td>
                                    </tr>
                                    <tr>
                                        <td>Replace all</td>
                                        <td>Shift-Ctrl-R / Shift-Cmd-Alt-F</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
                <div class="form-group">
                    <a href="javascript:void(0);" class="text-purple" popoverClass="font-list-popover" container="body" [ngbPopover]="availableFontsTpl" triggers="click" placement="bottom">
                        <i class="fas fa-font"></i> <span class="ml-2">List of Available Fonts</span>
                    </a>
                </div>
                <div class="form-group">
                    <a href="javascript:(0);" class="text-purple" (click)="previewTemplate()"><i class="fas fa-file-pdf"></i> <span class="ml-2">Preview Template</span></a>
                </div>
            </div>
        </div>
        <hr>
        <!-- <ckeditor [config]="ckeditorConfig" [(ngModel)]="prop.tplContent" (ready)="editorOnReady($event)">
        </ckeditor> -->
        <textarea id="codemirrorArea" name="code_editor">{{prop.tplContent}}</textarea>
        <ngx-loading [show]="loading" [config]="{ backdropBorderRadius: '4px' }"></ngx-loading>
    </div>

    <div class="app-modal-footer">
        <button type="button" class="btn btn-outline-purple mr-3" data-dismiss="modal" (click)="close()">Close</button>
        <button type="button" class="btn btn-purple mr-3" data-dismiss="modal" (click)="save(true)" [disabled]="loading">
            <i class="feather icon-check-circle"></i>
            &nbsp;Save & Close
        </button>
        <button type="button" class="btn btn-purple" data-dismiss="modal" (click)="save(false)" [disabled]="loading">
            <i class="feather icon-check-circle"></i>
            &nbsp;Save
        </button>
    </div>

</app-ui-modal>

<app-ui-modal #previewTemplateModal dialogClass="modal-dialog-centered modal-xl" [containerClick]="false">
  <div class="app-modal-header">
    <h5 class="modal-title">Preview the Email template</h5>
    <button type="button" class="close basic-close" data-dismiss="modal" aria-label="Close"
      (click)="previewTemplateModal.hide()"><span aria-hidden="true">&times;</span></button>
  </div>
  <div class="app-modal-body overflow-auto" style="height: 70vh;">
    <div class="innerhtml" [innerHtml]="emailContent"></div>
  </div>
  <div class="app-modal-footer">
    <button type="button" class="btn btn-outline-purple mr-4" data-dismiss="modal"
      (click)="previewTemplateModal.hide()">Close</button>
  </div>
</app-ui-modal>

<ng-template #availableFontsTpl>

    <table class="table custom-mini-table">
        <tbody>
            <tr>
                <td><b>Name</b></td>
                <td><b>Variance</b></td>
            </tr>
            <tr *ngFor="let item of availableFontList">
                <td>{{item.name}}</td>
                <td class="styles">
                  <span class="style-item" *ngFor="let style of item.styles" [ngSwitch]="style">
                    <span *ngSwitchCase="''">Regular</span>
                    <span *ngSwitchCase="'B'">Bold</span>
                    <span *ngSwitchCase="'I'">Italic</span>
                    <span *ngSwitchCase="'BI'">Bold Italic</span>
                  </span>
                </td>
            </tr>
        </tbody>
    </table>
</ng-template>
