/* eslint-disable  */
import { Injectable } from '@angular/core';
import CryptoJS from 'crypto-js';
// const SecureStorage = require('secure-web-storage');
import SecureStorage from 'secure-web-storage';

const SECRET_KEY = 'secret_key';

@Injectable({
  providedIn: 'root',
})
export class CryptService {

  key = CryptoJS.enc.Latin1.parse('1111111111111111');
  iv = this.key;
  constructor() { }

  public secureStorage = new SecureStorage(localStorage, {
    hash: function hash(data) {
      return data;
    },
    // Encrypt the localstorage data
    encrypt: function encrypt(data) {
      data = CryptoJS.AES.encrypt(data, SECRET_KEY);
      data = data.toString();
      return data;
    },
    // Decrypt the encrypted data
    decrypt: function decrypt(data) {
      data = CryptoJS.AES.decrypt(data, SECRET_KEY);
      data = data.toString(CryptoJS.enc.Utf8);
      return data;
    },
  });

  Crypt(password) {
    return CryptoJS.AES.encrypt(password, this.key, {
      iv: this.iv,
      mode: CryptoJS.mode.CBC,
    }).toString();
  }

  Decrypt(data) {
    return CryptoJS.AES.decrypt(data, this.key, {
      iv: this.iv,
      mode: CryptoJS.mode.CBC,
    }).toString();
  }
}
