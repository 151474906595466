import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { CommonService } from 'src/app/service/common/common.service';

@Injectable({
  providedIn: 'root'
})
export class RoleGuard implements CanActivate {
  constructor(private router: Router, private commonService: CommonService) { }
  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    const role = this.commonService.getJsonValue('role');
    // console.log(role);
    // console.log(state.url);

    // const pattern = /^(.+?)\/.+?\/.+/;
    const pattern = /^([^/]+)\/.+$/;
    const match = state.url.match(pattern);
    const region = match && match[1] ? match[1] : '/au';
    if (role === 'BROKER') {
      if (!/^.+\/broker/.test(state.url) && !/^.+\/page/.test(state.url)) {
        this.router.navigate([`${region}/broker/workbook`]);
      } else {
        return true;
      }
    }
    if (role === 'UW') {
      if (!/^.+\/uw/.test(state.url) && !/^.+\/page/.test(state.url)) {
        this.router.navigate([`${region}/uw/home`]);
      } else {
        return true;
      }
    }
    return true;
  }
}

