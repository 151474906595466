import { Component, NgZone, OnInit } from '@angular/core';
import { NextConfig } from '../../../app-config';
import { Location } from '@angular/common';
import { CommonService } from '../../../service/common/common.service';
import { UserService } from '../../../service/user/user.service';
import { DataShareService } from '../../../service/data-share/data-share.service';
import { TraceUrl } from './trace.url';

import { NavigationEnd, Router } from '@angular/router';
import Swal from 'sweetalert2';
import { Subscription } from 'rxjs';


@Component({
  selector: 'app-admin',
  templateUrl: './admin.component.html',
  styleUrls: ['./admin.component.scss'],
})
export class AdminComponent implements OnInit {
  public nextConfig: any;
  public navCollapsed: boolean;
  public navCollapsedMob: boolean;
  public windowWidth: number;

  public username = '';
  public companyname = '';
  public firstname = '';
  public lastname = '';
  public usertype = '';
  loadTimer: string;
  subscription: Subscription;

  constructor(
    private zone: NgZone,
    private location: Location,
    private router: Router,
    public commonService: CommonService,
    public userService: UserService,
    private dataShareService: DataShareService,
  ) {
    this.nextConfig = NextConfig.config;
    let currentURL = this.location.path();
    // eslint-disable-next-line @typescript-eslint/dot-notation
    const baseHerf = this.location['_baseHref'];
    if (baseHerf) {
      currentURL = baseHerf + this.location.path();
    }

    this.windowWidth = window.innerWidth;

    if (
      (currentURL === baseHerf + '/layout/collapse-menu' ||
        currentURL === baseHerf + '/layout/box') &&
      this.windowWidth >= 992 &&
      this.windowWidth <= 1024
    ) {
      this.nextConfig.collapseMenu = true;
    }

    this.navCollapsed =
      this.windowWidth >= 992 ? this.nextConfig.collapseMenu : false;
    this.navCollapsedMob = false;
  }

  async ngOnInit() {
    this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        // Do something when the route changes
        this.loadTimer = '';
      }
    });

    this.bindHttpTrace();

    if (this.windowWidth < 992) {
      this.nextConfig.layout = 'vertical';
      setTimeout(() => {
        document
          .querySelector('.pcoded-navbar')
          .classList.add('menupos-static');
        (
          document.querySelector('#nav-ps-next') as HTMLElement
        ).style.maxHeight = '100%'; // 100% amit
      }, 500);
    }

    const res = await this.userService.getUser();
    const success = this.commonService.getJsonValue('success');

    if (success !== 'true') {
      const msg = typeof res === 'object' ? res.message : res;
      Swal.fire({
        text: msg,
        title: 'Error',
        icon: 'error',
      });
    }
  }

  navMobClick() {
    if (this.windowWidth < 992) {
      if (
        this.navCollapsedMob &&
        !document
          .querySelector('app-navigation.pcoded-navbar')
          .classList.contains('mob-open')
      ) {
        this.navCollapsedMob = !this.navCollapsedMob;
        setTimeout(() => {
          this.navCollapsedMob = !this.navCollapsedMob;
        }, 100);
      } else {
        this.navCollapsedMob = !this.navCollapsedMob;
      }
    }
  }

  bindHttpTrace() {
    this.subscription = this.dataShareService.getData().subscribe((res) => {
      if (res && res.name === 'httpTrace') {
        const data = res.data;
        const url = data.url || '';
        if (this.checkHttpTrace(url)) {
          const diff = data.diff || 0;
          const timer = diff / 1000;
          const randomNum = Math.floor(Math.random() * 900) + 100;
          this.loadTimer = timer.toString() + randomNum.toString();
        }
      }
    });
  }

  checkHttpTrace(url) {
    if (!url) {
      return false;
    }
    url = url.trimEnd('/');
    return TraceUrl.items.includes(url);
  }
}
