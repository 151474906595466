import { Injectable } from '@angular/core';
import {
  HttpEvent,
  HttpInterceptor,
  HttpHandler,
  HttpRequest,
  HttpErrorResponse,
  HttpResponse,
  HttpClient,
} from '@angular/common/http';
import { Observable } from 'rxjs/Observable';
import 'rxjs/add/operator/do';
import { Router } from '@angular/router';
import { CommonService } from './service/common/common.service';
import { CryptService } from './service/crypt/crypt.service';
import { environment } from 'src/environments/environment';
import { DataShareService } from './service/data-share/data-share.service';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  constructor(
    private router: Router,
    private http: HttpClient,
    private cryptService: CryptService,
    private commonService: CommonService,
    private dataShareService: DataShareService,
  ) { }
  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    //加密?后面的参数
    const region = this.commonService.getJsonValue('region') || environment.country.toLowerCase();
    let apiurl = environment[`${region}ApiUrl`];
    if (!apiurl) {
      apiurl = environment.auApiUrl;
    }
    const urls = req.url.split('?');
    let newurl = apiurl + urls[0];
    if (urls[0].startsWith('http://') || urls[0].startsWith('https://')) {
      newurl = urls[0];
    }
    if (urls.length > 1) {
      newurl +=
        '?_param=' + encodeURIComponent(this.cryptService.Crypt(urls[1]));
    }
    const token = this.commonService.getJsonValue('token');
    let contentType = 'application/json';
    if (req.body instanceof FormData) {
      contentType = '';
    }
    let authReq;

    const startTime = Date.now();
    if (req.url !== '/user/login' && token) {
      if (contentType) {
        authReq = req.clone({
          url: newurl,
          headers: req.headers
            .set('Authorization', 'Bearer ' + token)
            .set('Content-Type', contentType),
          body: this.parseData(req.body, contentType),
        });
      } else {
        authReq = req.clone({
          url: newurl,
          headers: req.headers
            .set('Authorization', 'Bearer ' + token)
            .delete('Content-Type'),
          body: this.parseData(req.body, contentType),
        });
      }
    } else {
      authReq = req.clone({
        url: newurl,
        headers: req.headers.set('Content-Type', contentType),
        body: this.parseData(req.body, contentType),
      });
    }
    return next.handle(authReq).do(
      (event: HttpEvent<any>) => {
        if (event instanceof HttpResponse) {
          if (event.headers.get('Token-Expired') === 'true') {
            this.router.navigate(['/session-error'], {
              queryParams: {
                _params: this.commonService.paramsEncode({ type: 'expired2' }),
              },
            });
          }
        }
      },
      (err: any) => {
        if (err instanceof HttpErrorResponse) {
          console.log(err);
          if (err.status === 401) {
            this.commonService.clearToken();
            this.router.navigate(['auth/session-error'], {
              queryParams: {
                _params: this.commonService.paramsEncode({
                  type: 'access_denied',
                }),
              },
            });
          } else {
            console.log(err.name);
            console.log(err.message);
            console.log(err.statusText);
          }
        }
      },
      () => {
        const endTime = Date.now();
        const diff = endTime - startTime;
        const data = {
          url: urls[0],
          diff
        };
        this.dataShareService.sendData({name: 'httpTrace', data});
      }
    );
  }
  //加密
  public parseData(obj, contentType) {
    if (obj) {
      if (contentType === 'application/json') {
        const newdata = {};
        // eslint-disable-next-line @typescript-eslint/dot-notation
        newdata['_param'] = this.cryptService.Crypt(JSON.stringify(obj));
        return newdata;
      } else {
        //form-data提交方式
        const formData = obj;
        const newformData = new FormData();
        formData.forEach((value, key) => {
          if (value instanceof File) {
            newformData.append(key, value);
          } else {
            newformData.append(
              this.cryptService.Crypt(key),
              this.cryptService.Crypt(value)
            );
          }
        });
        return newformData;
      }
    }
  }
}
