import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FileUploadModule } from '@iplab/ngx-file-upload';
import { UiModalComponent } from './ui-modal/ui-modal.component';
import { AnimationModalComponent } from './animation-modal/animation-modal.component';
import { UploadTemplateComponent } from './upload-template/upload-template.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

@NgModule({
  imports: [
    CommonModule,
    FileUploadModule,
    FormsModule,
    ReactiveFormsModule
  ],
  declarations: [UiModalComponent, AnimationModalComponent, UploadTemplateComponent],
  exports: [UiModalComponent, AnimationModalComponent, UploadTemplateComponent]
})
export class ModalModule { }
