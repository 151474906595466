import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';

// import * as LogRocket from 'logrocket';

if (environment.production) {
  enableProdMode();
}

platformBrowserDynamic().bootstrapModule(AppModule)
  .catch(err => console.error(err));

// if (environment.env === 'STAGING') {
//   LogRocket.init('npu4cq/asop-uwbroker-staging');
// }

// if (environment.env === 'LIVE') {
//   LogRocket.init('npu4cq/asop-uwbroker-live');
// }
