import { Component, OnInit, Input } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Md5 } from 'ts-md5/dist/md5';
import * as moment from 'moment';
import { CommonService } from '../../../../service/common/common.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-login-carousel',
  templateUrl: './login-carousel.component.html',
  styleUrls: ['./login-carousel.component.scss']
})
export class LoginCarouselComponent implements OnInit {

  today: string = moment().format('dddd DD MMM YYYY');

  imgagsBanner: Array<any>;
  publicSiteUrl = environment.officialWebsite;

  @Input() set banners(value: Array<any>) {
    this.imgagsBanner = value;
  }

  get banners(): Array<any> {
    return this.imgagsBanner;
  }

  constructor(
    private http: HttpClient,
    private commonService: CommonService
  ) { }

  ngOnInit(): void {
    this.imgagsBanner = this.imgagsBanner || [
      {
        image: 'assets/images/auth/img-pi.jpg',
        title: 'PI Shield',
        subTitle: 'Professional indemnity insurance exclusively for accountants.',
        description: '',
        link: this.publicSiteUrl + '/products-services/accountant-s-pi/'
      },
      {
        image: 'assets/images/auth/img-cyber.jpg',
        title: 'Cyber Shield',
        subTitle: 'Trusted cyber insurance when you need it the most.',
        description: '',
        link: this.publicSiteUrl + '/products-services/cyber-insurance/'
      },
      {
        image: 'assets/images/auth/img-blog2.jpg',
        title: 'Latest Blog',
        subTitle: 'Think Tank Proposes Standardised Deduction To Simplify Tax System',
        description: `The Blueprint Institute has just released a report dubbed ‘Bye-Bye Tax Returns’ that includes a tax reform proposal that would see over nine million taxpayers given the option to avoid having to itemise their expenses and rather opt-in for a standardised $3,000 deduction. This would apply to work-related expenses and other personal deductions, meaning taxpayers […]`,
        link: this.publicSiteUrl + '/bragg-calls-for-tax-reforms'
      },
    ];
    this.getBlog();
  }

  getBlog() {
    this.http.get('/user/getSiteLatestBlog',).subscribe((res: any) => {
      if (res.code === 200 && res.data) {
        this.imgagsBanner[2].subTitle = res.data.title;
        this.imgagsBanner[2].description = res.data.description;
        this.imgagsBanner[2].link = res.data.link;
        this.imgagsBanner[2].image = res.data.image;
      }
    });
  }
}
