import { BsDatepickerConfig } from 'ngx-bootstrap/datepicker';

export const appDatepickerConfig = Object.assign(new BsDatepickerConfig(), {
  dateInputFormat: 'DD/MM/YYYY',
  containerClass: 'theme-purple',
  adaptivePosition: true,
  customTodayClass: 'custom-today-class',
  showTodayButton: true,
  todayPosition: 'right',
  // showClearButton: true,
  // clearPosition: 'left'
});
