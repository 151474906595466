<ul class="navbar-nav ml-auto">
  <li>
    <div class="dropdown mr-3" ngbDropdown placement="auto">
      <a appToggleFullScreen ngbDropdownToggle data-toggle="dropdown" href="javascript:"><i class="icon feather icon-maximize full-screen"></i></a>
    </div>
  </li>
  <li>
    <div class="dropdown drp-user" ngbDropdown placement="auto">
      <a href="javascript:" ngbDropdownToggle data-toggle="dropdown" style="width: auto;">
        <span class="mr-2">{{firstname}} {{lastname}} ({{username}})</span>
        <i class="icon feather icon-user"></i>
      </a>
      <div class="dropdown-menu dropdown-menu-right profile-notification" ngbDropdownMenu>
        <ul class="pro-body" *ngIf="!isBroker">
          <li><a routerLink="uw/admin/add-account" class="dropdown-item"><i class="feather icon-plus-square"></i> Add Account</a></li>
          <li><a routerLink="uw/admin/account-list" class="dropdown-item"><i class="feather icon-edit"></i> Edit Account</a></li>
          <!-- <li><a routerLink="uw/admin/change-password" class="dropdown-item"><i class="feather icon-mail"></i> Change Password</a></li> -->
          <li><a href="javascript:" (click)="logout()" class="dropdown-item"><i class="feather icon-power"></i> Logout</a></li>
        </ul>
        <ul class="pro-body" *ngIf="isBroker">
          <!-- <li><a routerLink="broker/admin/add-account" class="dropdown-item"><i class="feather icon-plus-square"></i> Add Account</a></li> -->
          <!-- <li><a routerLink="broker/admin/account-list" class="dropdown-item"><i class="feather icon-edit"></i> Edit Account</a></li> -->
          <li><a href="javascript:" (click)="logout()" class="dropdown-item"><i class="feather icon-power"></i> Logout</a></li>
        </ul>
      </div>
    </div>
  </li>
</ul>
