import { Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment';

@Pipe({
  name: 'calcDateDuration'
})
export class CalcDateDurationPipe implements PipeTransform {

  transform(source: any, format: string): string {
    if (!source) {
      return '';
    }

    const date = moment(source, format);
    if (!date.isValid()) {
      return 'N/A';
    }

    const res = moment().diff(date, 'days', true);
    const days = Math.ceil(res);
    return `${days} days`;
  }

}
